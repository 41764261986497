.match-update-dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .match-update-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .column {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        width: 50px;
        color: white;
        padding: 20px 20px;
        height: 110px;

        div.center-column-container {
          padding-top: 20px;
        }

        div.result {
          display: flex;
          width: 100px;
          font-size: 25px;
        }

        div.status {
          font-size: 12px;
          padding-top: 3px;
        }

        p.team-name {
          font-size: 13px;
          width: 100px;
        }

        > img {
          width: 60px;
          height: 60px;
          object-fit: contain;
        }
      }
    }
  }
}
