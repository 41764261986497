div.admin-match {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid lightgrey;
  background-color: white;
  padding: 5px 20px;
  border-radius: 15px;
  margin: 10px 0px;
  position: relative;

  div.column1 {
    font-size: 12px;
    width: 20%;
  }

  div.column2 {
    display: flex;
    flex-direction: column;
    width: 40%;

    div.team {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        font-size: 12px;
      }

      img {
        padding-right: 10px;
        max-width: 20px;
        max-height: 20px;
      }
    }
  }

  div.column3 {
    font-size: 12px;
    width: 15%;
  }

  div.column4 {
    text-align: center;
    font-size: 12px;
    width: 80px;
    border-radius: 20px;
    width: 15%;
  }
}
