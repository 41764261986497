$color-orange: #ff6b00;
$color-white: #fdfdfd;
$color-green: #2e7d32;
$color-light-grey: #aaaaaa;
$color-medium-grey: #1c1c1c;
$color-dark-grey: #181818;
$color-darker-grey: #111111;
$color-black: #0b0b0b;

$color-text-white: #fdfdfd;
$color-text-grey: #aaaaaa;
