div.knockout-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  div.knockout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    div.lines-container {
      display: flex;
      justify-content: space-around;
      width: 100%;
      min-width: 2000px;
      flex-direction: row;
      height: auto;

      div.line-container {
        display: flex;
        align-items: center;
        height: 100%;
        flex-direction: column;
        margin: 3px;

        div.arrow {
          border: 1px solid gray;
          flex-direction: column;
          height: 15px;
          width: 0px;
          margin: 0px;
        }

        div.box {
          border-left: 0px;
          border: 1px solid gray;
          border-bottom: 0px;
          border-radius: 10px 10px 0px 0px;
          height: 35px;
          margin: 0px 5px;
        }
      }
    }

    div.knockout-teams {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      height: auto;
      width: 100%;
      min-width: 2000px;

      div.knockout-match {
        div.team-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 230px;
          border-radius: 15px;
          background-color: #e9eff3;
          border: 1px solid gray;
          margin: 2px 0px;

          div.team-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 100%;
            border-radius: 15px;
            padding: 0px 10px;
            font-size: 15px;
            color: black;

            img {
              height: 22px;
              width: 22px;
              border-radius: 50%;
              margin-right: 6px;
            }
          }

          div.point-container {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #97bec8;
            width: 50px;
            height: 30px;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            font-size: 15px;
            color: white;
          }
        }
      }
    }
  }
}
