.create-team-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 250px;
  margin-top: 30px;
  width: 100%;
}

div.create-team-row {
  display: flex;
  width: 300px;
  height: 50px;
}

div.admin-create-team-group-field {
  display: flex;
  justify-content: space-evenly;
  width: 100px;
}

div.create-team-dropdown-container {
  margin: 0px 3px;
}

div.admin-team-list {
  margin: 10px;
}

div.admin-team {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  width: 150px;
}

p.admin-team-name {
  color: white;
  font-size: 16px;
}

img.admin-team-logo {
  width: 15px;
  height: 15px;
  padding: 10px 5px 10px 5px;
}

div.admin-create-team-image-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  max-height: 100px;
}
