div.earlier-matches-container {
  background-color: #1b191a;
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 100px;

  div.earlier-matches-header {
    background-color: rgb(10, 10, 10);
    display: flex;
    justify-content: center;
    color: white;
    font-size: 14px;
    padding: 10px;
    letter-spacing: 2px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  div.earlier-matches-match {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    padding: 10px 0px;

    div.earlier-match-row {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      height: 25px;
      width: 100%;

      div.earlier-match-team {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;

        img {
          max-height: 12px;
          max-width: 12px;
          margin: 5px;
        }
      }

      div.earlier-match-result {
        text-align: center;
        margin: 0px 11px;
        width: 50px;
      }
    }

    div.earlier-match-tournament-data {
      font-size: 10px;
      margin-top: 8px;
    }
  }

  p.no-data {
    text-align: center;
    color: white;
  }
}
