div.contact-page {
  height: 100vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

div.contact-row {
  display: flex;
  margin-top: 20px;
  color: white;
}
