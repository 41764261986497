div.admin-wrapper {
  display: grid;
  grid-template-areas:
    "menu header"
    "menu main";

  grid-template-columns: 15% 85%;

  width: 100%;
  height: 100%;
  background-color: white;

  .header {
    background-color: rgba(255, 255, 255, 0.95);
    grid-area: header;
    border-bottom: 1px solid rgb(245, 245, 245);
    display: flex;
    justify-content: flex-end;
    height: 50px;
    width: 100%;
  }

  .side-menu {
    grid-area: menu;
    background-color: #1c2536;
    height: 100%;
    padding: 0px 20px;

    div.side-menu-top-container {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid rgb(47, 55, 70);

      img.tf-logo {
        height: 80px;
        margin: 40px;
      }
    }

    div.side-menu-bottom-container {
      margin-top: 20px;
    }
  }

  .main-content {
    grid-area: main;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: calc(100vh - 51px);
    overflow-y: auto;

    div.admin-top-container {
      display: flex;
      justify-content: space-between;
      margin-top: 100px;
      margin-left: 100px;
      margin-right: 100px;
      width: calc(100% - 200px);

      div.admin-top-container-button-container {
        display: flex;
        align-items: center;
      }
    }

    div.admin-content-container {
      display: flex;

      justify-content: flex-start;
      margin-left: 100px;
      margin-right: 100px;
      width: calc(100% - 200px);
    }
  }
}
