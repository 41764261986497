.match {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  background-color: $color-dark-grey;
  color: $color-light-grey;
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;

  .column-0 {
    border-radius: 0 7.5px 7.5px 0;
    content: "";
    display: block;
    height: calc(100% - 18px);
    position: absolute;
    top: 10px;
    width: 5px;
    background-color: $color-green;
  }

  .column-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    font-size: 11px;
    margin: 0px 18px;
  }

  .column-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    min-width: 170px;
    white-space: nowrap;
    overflow: hidden;

    .team {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      font-size: 16px;

      img {
        max-height: 20px;
        max-width: 20px;
        margin: 10px 10px 10px 0px;
      }

      span {
        font-size: 11.5px;
        white-space: nowrap;
      }
    }
  }

  .column-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 65px;
    color: #888888;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
  }

  .column-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50px;
    margin: 0px 15px;
    height: 80%;

    .point {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      font-size: 14px;
      color: white;
    }
  }
}

div.matches-list-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $color-text-grey;
  width: 98%;
  height: 25px;
  font-size: 13px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 5px;
  color: $color-orange;
}

div.knock-out-label {
  position: relative;
  text-align: center;
  color: black;
  background-color: orange;
  border-radius: 5px;
  font-size: 10px;
  width: fit-content;
  z-index: 1;
  padding: 5px 15px;
  left: 25px;
  top: 17px;
  width: 70px;
}
